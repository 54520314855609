import Modal from "./Modal"
import OnClickButton from "./OnClickButton"
import "../pages/members/css/member-handicaps.css"
import { useModal } from "../utils/hooks/useModal"
import ALink from "./ALink"


function HandicapTableLayout({buttons, members, modalData})
{
    const {currentModalData, showModal,modalOpen, modalClose, modalSave} = useModal()

    const handleClick = (button) => {
        if(button.modal && button.modalDataKey)
        {
            modalOpen(modalData[button.modalDataKey])

        }
        else if(button.clickHandler)
        {
            button.clickHandler()
        }
    }

    return(
        <>
        <div className="list-table-layout">
        <h3>Member Handicaps</h3>
        { 
            buttons.map((button) => {
                return <OnClickButton key={button.key} text={button.text} onClickHandler={() => handleClick(button)} permission_max_level={button.permission_max_level}/>
            })
        }
        <div className="handicap-table">
        <div className="handicap-table-header">
            <div className="row handicap-row">
                <div className="col-2 member-name">
                    Member
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-4 member-discipline">
                            Discipline
                        </div>
                        <div className="col-1 member-handicap">
                            Handicap
                        </div>
                    </div>
                    

                </div>
                
            </div>
        </div>
        {
            members.map((member) => {
                return(
                    <div key={member.state_sid} className="row handicap-row">
                        <div className="col-2 member-name">
                            {member.user.full_name}
                        </div>
                        <div className="col-6">
                            {
                                member.competitors.map((competitor) => {
                                    return(
                                        <div key={competitor.id} className="row">
                                            <div className="col-4 member-discipline">
                                                <ALink href={"/home/members/" + member.state_sid + "/handicaps/" + competitor.id +"/"}>{competitor.handicap_type.name}</ALink>
                                            </div>
                                            <div className="col-1 member-handicap">
                                                {competitor.shooting_handicap ? competitor.shooting_handicap : "INV"}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                )
            })
            
        }
        </div>
        </div>
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
        </>
    )
}

export default HandicapTableLayout