import React from "react";
import { useAuth } from "../utils/hooks/useAuth"

const OnClickButton = ({text = "Button", id, onClickHandler, permission_max_level=3, permission_id_override = null}) => {
    const {user} = useAuth()

    if(user.role.level <= permission_max_level || permission_id_override === id)
    {
        return(
            <button type="button" className="btn btn-primary list-layout-button" onClick={onClickHandler}>{text}</button>
        )
    }
    else return
}

export default OnClickButton