import { useEffect, useState } from "react"
import { api_post } from "./api"

export async function postData(url, post_data){

    let data = null
    let error = null
    let loading = false
 
    try
    {
        loading = true
        const response = await api_post(url, post_data)
        data = response.data
    }
    catch(_error)
    {
        error = _error
    }
    finally
    {
        loading = false
    }
            
    return {data, error, loading}
}