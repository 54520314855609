import Modal from "./Modal"
import OnClickButton from "./OnClickButton"
import "../pages/members/css/member-handicaps.css"
import { useModal } from "../utils/hooks/useModal"
import ALink from "./ALink"
import ContentMenu from "./ContentMenu"


function HandicapDetailLayout({contentMenu, buttons = [], competitor, modalData = {}, deleteHandler})
{
    const {currentModalData, showModal,modalOpen, modalClose, modalSave} = useModal()

    const handleClick = (button) => {
        if(button.modal && button.modalDataKey)
        {
            modalOpen(modalData[button.modalDataKey])

        }
        else if(button.clickHandler)
        {
            button.clickHandler()
        }
    }

    return(
        <>
        <ContentMenu backUrl={contentMenu.backUrl} backText={contentMenu.backText} buttons={contentMenu.buttons}/>
        <div className="list-table-layout">
        { 
            buttons.map((button) => {
                return <OnClickButton key={button.key} text={button.text} onClickHandler={() => handleClick(button)} permission_max_level={button.permission_max_level}/>
            })
        }
        {competitor &&
            <div className="handicap-detail">
                <h3>Handicap Summary</h3>
                <table className="handicap-detail-summary">
                    <thead>
                        <tr>
                            <th>Member</th>
                            <th>Discipline</th>
                            <th>Exact Handicap</th>
                            <th>Handicap</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{competitor.member.user.full_name}</td>
                            <td>{competitor.handicap_type.name}</td>
                            <td className="handicap-bold">{competitor.handicap}</td>
                            <td className="handicap-bold">{competitor.shooting_handicap}</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Score History</h3>
                <table className="handicap-detail-history">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Event</th>
                            <th>Score</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                {
                    competitor.handicap_scores.map((score) => {
                        return(
                            <tr key={score.id} className={score.counter ? "" : "no-counter"}>
                                <td width="64px" className="handicap-flag">{score.counter ? <img width="32px" src="/images/flag.png"></img> : ""}</td>
                                <td className="handicap-date">{(new Date(Date.parse(score.date))).toDateString()}</td>
                                <td className="handicap-event">Manually Entered Score</td>
                                <td className="handicap-score">{score.aggregate_score}</td>
                                <td className="handicap-delete"><ALink href="" onClick={(event) => deleteHandler(event, score.id)} permission_max_level={2}>Delete</ALink></td>
                            </tr>
                        )
                    })
                    
                }
                    </tbody>
                </table>
            </div>
        }
        </div>
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
        </>
    )
}

export default HandicapDetailLayout