import { useAuth } from "../utils/hooks/useAuth"

function ALink({href, id, className=null, onClick, permission_max_level=3, permission_id_override=null, children})
{
    const {user} = useAuth()

    if(user.role.level <= permission_max_level || permission_id_override === id)
    {
        return (
            <>
                <a href={href} className={className && className} onClick={onClick}>{children}</a>
            </>
            
        )
    }
    else
    {
        return
    }
    
}

export default ALink