import {React, useContext} from "react";
import ALink from "./ALink";
import { useAuth } from "../utils/hooks/useAuth"
import { useModal } from "../utils/hooks/useModal";



const TableRow = ({id, data, url, edit, editHandler, del, deleteHandler, extraOptions=[]}) => {
    
    const {openModal} = useModal()
    const {user} = useAuth()

    
    return (
        <tr>
            {data.map((item) => {
                if(url && item.url)
                {
                    return (
                        <td key={id + item.data + "data"}>
                            <a href={url + id + "/"}>{item.data}</a>
                        </td>
                        )
                }
                return <td key={id + item.data + "data"}>{item.data}</td>;
                
            })}
            {
                extraOptions && 
                extraOptions.map((option) => {
                    if(option.link)
                    {
                        return(<td key={option.name}><ALink id={id} href="" onClick={(event) => option.link_handler(event, id)} permission_max_level={option.permission_max_level} permission_id_override={option.permission_id_override}>{option.name}</ALink></td>)
                    }
                })
            }
            {(url && edit) && <td><ALink href={""} id={id} onClick={(e) => editHandler(e, id)} permission_max_level={2} permission_id_override={user.username}>Edit</ALink></td>}
            {(url && del) && <td><ALink href={""} id={id} onClick={(e) => deleteHandler(e, id)} permission_max_level={2}>Delete</ALink></td>}
        </tr>
    );
};

export default TableRow;