import './App.css';
import { Routes, Route, useNavigate} from 'react-router-dom';
import Home from './pages/Home'
import Login from "./pages/Login"
import MembersHome from './pages/members/MembersHome';
import ClubDetailsHome from './pages/clubdetails/ClubDetailsHome';
import EventsHome from './pages/events/EventsHome';
import TrophiesHome from './pages/trophies/TrophiesHome';
import SettingsHome from './pages/settings/SettingsHome';
import MemberDetail from './pages/members/MemberDetail';
import MemberDetails from './pages/members/MemberDetails';
import MembersHandicaps from './pages/members/MembersHandicaps';
import MembersHandicapDetail from './pages/members/MembersHandicapDetail';
import MemberHandicapTypes from './pages/members/MemberHandicapTypes';
import MemberHandicapTypeDetail from './pages/members/MemberHandicapTypeDetail';
import { createContext } from 'react';
import Cookies from 'universal-cookie'
import { ProtectedRoute } from './components/ProtectedRoute';
import { AdminRoute } from './components/AdminRoute';
import { AuthProvider } from './utils/hooks/useAuth';
import { ModalProvider } from './utils/hooks/useModal';

const cookies = new Cookies()

export const cookiesContext = createContext(cookies)

function App() {


  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={
            <ModalProvider>
              <Routes>
                <Route path="/home" element={<ProtectedRoute><Home/></ProtectedRoute>} >
                  <Route path="/home/members" element={<MembersHome />}>
                    <Route index element={<MemberDetails />}>      
                    </Route>
                    <Route path="/home/members/:username" element={<MemberDetail />} />
                    <Route path="/home/members/handicaps" element={<MembersHandicaps />}>
                    </Route>
                    <Route path="/home/members/:username/handicaps/:id" element={<MembersHandicapDetail />} />
                    <Route path="/home/members/handicaptypes" element={<AdminRoute><MemberHandicapTypes /></AdminRoute>}>
                    </Route>
                    <Route path="/home/members/handicaptypes/:id" element={<AdminRoute><MemberHandicapTypeDetail /></AdminRoute>} />
                    
                  </Route>
                  <Route path="/home/clubdetails" element={<ClubDetailsHome />}>

                  </Route>
                  <Route path="/home/events" element={<EventsHome />}>

                  </Route>
                  <Route path="/home/trophies" element={<TrophiesHome />}>

                  </Route>
                </Route>
              </Routes>
              </ModalProvider>
          } />
        </Routes>
      </AuthProvider>
    </>
    
  );

}



export default App;
